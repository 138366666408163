import { Fragment, useCallback } from "react";
import { v4 as uuidv4 } from 'uuid';
import { SIDEBAR_ITEM, COMPONENT, COLUMN } from "../../../../components/DragNDrop/constants";
import DropZone from "../../../../components/DragNDrop/DropZone";
import Row from "../../../../components/DragNDrop/Row";
import { UpdateHubspotForm } from "../../../../utils/forms/HubspotForm";
import {
    handleMoveWithinParent,
    handleMoveToDifferentParent,
    handleMoveSidebarComponentIntoParent
} from "../../../../components/DragNDrop/helpers";
import { withTranslation } from "react-i18next";

function Setup({t, data, save, components, setComponents, layout, setLayout, handleDetails, ...props }) {
    function arrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    const handleDrop = useCallback(
        (dropZone, item) => {
            let position = parseInt(dropZone.path);
            if (data && data in data) {
                UpdateHubspotForm(data, item.component.data, dropZone.path);
            }
            const splitDropZonePath = dropZone.path.split("-");
            const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

            const newItem = { id: item.id, type: item.type };
            if (item.type === COLUMN) {
                newItem.children = item.children;
            }

            // sidebar into
            if (item.type === SIDEBAR_ITEM) {
                // 1. Move sidebar item into page
                // console.log(1)
                let newComponent = {
                    id: uuidv4(),
                    ...item.component
                };
                newComponent = JSON.parse(JSON.stringify(newComponent))
                newComponent.content = newComponent.data.name
                newComponent.data.error = false
                newComponent.data.conditionalList = null
                newComponent.data.optionsCustom = false
                newComponent.data.optionsCustomList = null
                newComponent.data.required = false
                newComponent.data.subQuestions = []
                newComponent.data.tooltip = null
                newComponent.data.value = null
                newComponent.data.visible = true
                newComponent.data.component = newComponent.data.fieldType
                if (typeof newComponent.data.options === "object" && newComponent.data.options.length === 0) {
                    newComponent.data.options = null
                }
                if (newComponent.data.realGroupName) {
                    if (newComponent.data.groupName === t("read only")) {
                        newComponent.data.readOnly = true
                    }
                    newComponent.data.groupName = newComponent.data.realGroupName
                }
                newComponent.data.object = newComponent.data.groupName
                newComponent.data.objectId = newComponent.data.groupName
                const newItem = {
                    id: newComponent.id,
                    type: COMPONENT
                };
                //for custom
                newComponent.data.id = newComponent.id;
                if (position > 0 && position < data.questions.length) { // if place betwen a conditionel and a sub, become a sub
                    // console.log(1)
                    newComponent.data.sub = data.questions[position].sub
                }
                UpdateHubspotForm(data, newComponent.data, position);
                setComponents({
                    ...components,
                    [newComponent.id]: newComponent
                });
                setLayout(
                    handleMoveSidebarComponentIntoParent(
                        layout,
                        splitDropZonePath,
                        newItem
                    )
                );
                save();
                if (newComponent.data.groupName !== 'custom tools' && newComponent.data.groupName !== "Outils personnalisés") {
                    handleDetails(newComponent.id, true);
                }
                return;
            }

            // move down here since sidebar items dont have path
            const splitItemPath = item.path.split("-");
            const pathToItem = splitItemPath.slice(0, -1).join("-");

            // 2. Pure move (no create)
            if (splitItemPath.length === splitDropZonePath.length) {
                // console.log(2)
                // 2.a. move within parent
                if (pathToItem === pathToDropZone) {
                    setLayout(
                        handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
                    );
                    return;
                }

                // 2.b. OR move different parent
                // TODO FIX columns. item includes children
                setLayout(
                    handleMoveToDifferentParent(
                        layout,
                        splitDropZonePath,
                        splitItemPath,
                        newItem
                    )
                );
                save();
                return;
            }

            // 3. Move + Create
            if (position <= data.questions.length) {
                // console.log(3)
                if (position > 0) { // if place betwen a conditionel and a sub, become a sub
                    // console.log(3.1)
                    data.questions[parseInt(splitItemPath[0])].sub = data.questions[position]?.sub
                }
                if (position > splitItemPath[0]) {
                    // console.log(3.2)
                    arrayMove(data.questions, parseInt(splitItemPath[0]), position - 1);
                } else {
                    // console.log(3.3)
                    arrayMove(data.questions, parseInt(splitItemPath[0]), position);
                }
            }
            let newLayout = handleMoveToDifferentParent(
                layout,
                splitDropZonePath,
                splitItemPath,
                newItem
            );
            newLayout.forEach((line, x) => {
                if (line.children[0].children.length === 0) {
                    newLayout.splice(x, 1);
                }
            })
            setLayout(newLayout);
            save();
        },
        [layout, components, data, save, setComponents, setLayout]
    );

    const renderRow = (row, currentPath) => {
        return (
            <Row
                key={row.id}
                data={row}
                handleDrop={handleDrop}
                components={components}
                path={currentPath}
                handleDetails={handleDetails}
                save={save}
                layout={layout}
                {...props}
            />
        );
    };

    const event = new Event("resize")

    window.dispatchEvent(event)

    //style={{ height: "inherit" }}
    return <div className="border-4 border-dashed border-gray-200 rounded-lg px-2">
        {layout?.map((row, index) => {
            const currentPath = `${index}`;

            return (
                <Fragment key={row.id}>
                    {/* {!components[row?.children[0]?.children[0]?.id]?.data?.controlled ? */}
                    <DropZone
                        data={{
                            path: currentPath,
                            childrenCount: layout.length
                        }}
                        onDrop={handleDrop}
                        path={currentPath}
                    />
        {/* : <div></div>} */}
                    {renderRow(row, currentPath)}
                </Fragment>
            );
        })}
        {layout.length ?
            <DropZone
                data={{
                    path: `${layout.length}`,
                    childrenCount: layout.length
                }}
                onDrop={handleDrop}
                isLast
            />
            :
            <DropZone
                data={{
                    path: `${layout.length}`,
                    childrenCount: layout.length
                }}
                onDrop={handleDrop}
                isLast
                className={"h-full"}
            />
        }
    </div>
}

export default withTranslation()(Setup);