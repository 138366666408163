import React from "react";
import LoadingSpinner from "../Animation/LoadingSpinner";

function SwitchFake({ value, loading, disabled }) {

    return loading ? (<LoadingSpinner />) : (
        <div disabled={disabled} type="button" aria-pressed="false" aria-labelledby="renew-headline" className={
            value ?
                "bg-primary-600 disabled:bg-gray-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200"
                : "bg-gray-200 disabled:bg-gray-100 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200"
        } >
            <span aria-hidden="true" className={value ?
                "translate-x-5 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                : "translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}></span>
        </div >
    )
}

export default SwitchFake;