import React, { useEffect, useState } from "react";

export default function Checkbox({ value, onClick, classname, ...props }) {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (value) {
            setChecked(value)
        }
    }, [value])

    const handleChange = () => {
        onClick(!checked)
        setChecked(!checked)
    }
    return <input type="checkbox"
        // className="form-checkbox h-5 w-5 text-primary-600"
        className={classname || "form-checkbox h-5 w-5 text-primary-600"}
        checked={checked}
        onChange={handleChange}
        {...props}
        />
}